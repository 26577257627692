import React from 'react';
import { navigate } from 'gatsby';
import './index.scss';
import { NotFoundIcon, H4, H1, BodyText, NormalButton } from '../../components';

const NotFound = () => {
  const onBackHome = () => {
    navigate('/');
  };
  return (
    <div className="not-found-container ">
      <div className="header-space" />

      <div className="main-container flex section-space horizontal-section-space items-center flex-column">
        <NotFoundIcon />
        <div className="space" />
        <H4 className="uppercase">Error 404</H4>
        <H1 className="title-text">Page Not Found</H1>
        <div className="space" />
        <BodyText className="text-center body-text">
          The page you are looking for does not exist. It may have been moved, or removed altogether. Perhaps you can
          return back to the site’s homepage and see if you can find what you are looking for.
        </BodyText>
        <div className="space" />
        <NormalButton label="GO BACK TO HOME" type="normal" onClick={onBackHome} />
      </div>
    </div>
  );
};

export default NotFound;
